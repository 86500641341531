<template>
  <div class="agreement">
    <div class="agreement-title">
      <p>礼轻送小程序</p>
      <p>用户协议</p>
    </div>
    <div>
      <b class="title">法律声明</b>
      <p>
        礼轻送提醒您:在使用礼轻送小程序各项服务前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用礼轻送小程序，但如果您使用礼轻送小程序，您的使用行为将被视为对本声明全部内容的认可。“礼轻送小程序"指由广东番茄科技有限公司(以下简称"番茄科技")运营的基于微信平台的网络交易程序。
        <br />
        <br />
        任何单位或个人认为礼轻送小程序内容（包括但不限于礼轻送小程序发布的商品信息)可能涉嫌侵犯其合法权益，应该及时向番茄科技提出书面权利通知，并提供身份证明、权属证明、具体链接(URL)或产品信息，及详细侵权情况证明。番茄科技在收到上述法律文件后，将会依法尽快移除相关涉嫌侵权的内容。
      </p>
    </div>
    <div>
      <b class="title">一、知识产权声明</b>
      <p>
        番茄科技拥有礼轻送小程序内所有信息内容(除礼轻送小程序合作商家发布的信息外，包括但不限于文字、图片、软件、音频、视频)的版权。
        <br />
        <br />
        任何被授权的浏览、复制、打印和传播属于礼轻送小程序内信息内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此版权声明。
        <br />
        <br />
        礼轻送小程序所有的产品、技术与所有程序均属于番茄科技知识产权。“礼轻送”以及礼轻送其他产品服务名称及相关图形、标识等为广东番茄科技有限公司的注册商标。未经广东番茄科技有限公司许可，任何人不得擅自(包括但不限于:以非法的方式复制、传播、展示、镜像、上载、下载)使用。否则，广东番茄科技有限公司将依法追究法律责任。
      </p>
    </div>
    <div>
      <b class="title">二、隐私声明</b>
      <p>
        礼轻送非常重视对您及您的收礼人的个人隐私保护，有时候我们需要某些信息才能为您及您的收礼人提供您及您的收礼人请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。
        <br />
        <br />
        礼轻送严格保护您及您的收礼人的个人信息(包括但不限于微信用户名、姓名、地址、联系电话等)的安全。我们使用各种安全技术和程序来保护您及您的收礼人的个人信息不被未经授权的访问、使用或泄漏。
        <br />
        <br />
        礼轻送会在法律要求或符合礼轻送的相关服务条款约定的情况下透露您的个人信息，或者有如下充分理由时:
        (a)满足法律或行政法规的明文规定，或者符合礼轻送适用的法律程序;
        (b)符合礼轻送相关服务条款的约定;
        (c)保护礼轻送的权利或财产，以及在紧急情况下保护礼轻送员工、礼轻送产品或服务的用户或大众的个人安全。
        <br />
        <br />
        除本声明已经列出的上述情况外，礼轻送不会未经您及您的收礼人的允许将这些信息与第三方共享。
      </p>
    </div>
    <div>
      <b class="title">三、免责声明</b>
      <p>
        1.任何第三方根据礼轻送规则列明的情况使用您及您的收礼人的个人信息，由此所产生的纠纷，礼轻送不承担任何法律责任。
        <br />
        <br />
        2.任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性小程序关闭，礼轻送不承担任何法律责任。
        <br />
        <br />
        3.我们鼓励您充分利用礼轻送平台自由地发表自己的评论和意见，您在礼轻送发表的内容(包括但不限于礼轻送目前各产品功能里的内容)仅表明您个人的立场和观点，并不代表礼轻送的立场或观点。
        <br />
        <br />
        4.您在礼轻送小程序中输入的内容(包括但不限于祝福语内容)应符合国家法律法规的规定，且不侵犯他人的个人隐私和合法权益。您在礼轻送发布侵犯他人知识产权或其他合法权益的内容，礼轻送有权予以删除，并保留移交司法机关处理的权利。
        <br />
        <br />
        5.礼轻送小程序上关于礼轻送平台用户或平台合作商家发布的相关商品(包括但不限于店铺名称、公司名称、联系人及联络信息，产品的描述和说明，相关图片、视讯等)的信息均视由用户及平台合作商家自行提供，用户及平台合作商家依法应对其提供的任何信息承担全部责任。
        <br />
        <br />
        6.互联网是一个开放平台，您将图片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，您必须充分意识此类风险的存在。您明确并同意其使用礼轻送服务所存在的风险将完全由其自己承担;因您使用礼轻送服务而产生的一切后果也由其自己承担，礼轻送对您不承担任何责任。
        <br />
        <br />
        7.对免责声明的解释、修改及更新权均属于礼轻送所有。
      </p>
    </div>
    <div>
      <b class="title">服务条款</b>
      <br />
      <br />
      <b class="title">一、服务条款的确认及接受</b>
      <p>
        根据国家法律法规变化及礼轻送小程序运营需要，礼轻送有权对本用户协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在礼轻送小程序上即生效，并取代此前相关内容。您知悉并确认，如您不同意更新后的内容，应立即停止使用礼轻送小程序;如您继续使用礼轻送小程序，即视为知悉变动内容并同意接受。
      </p>
    </div>
    <div>
      <b class="title">二、订单</b>
      <p>
        1.使用礼轻送小程序下订单，您应具备购买相关商品的权利能力和行为能力，如果您在18周岁以下，您需要在父母或监护人的监护参与下才能使用礼轻送小程序。在下订单的同时，即视为您满足上述条件，并对您将已下单商品发送给收礼人的行为及在订单中提供的所有信息的真实性负责。
        <br />
        <br />
        2.礼轻送有随时修改价格的权利而无需事先通知，支付价格为下订单时的有效价格，除非相关价格存在排版错误或其他明显不正确的价格。
        <br />
        <br />
        3.在您下订单时，请您仔细确认所购礼盒的描述、预算、联系地址、电话、收货人等信息。如遇描述与预算不符的，礼轻送相关工作人员会与您联系并确认。收礼人与您本人不一致的，收礼人的行为和意思表示视为您的行为和意思表示，您应对收礼人的行为及意思表示的法律后果承担连带责任。
        <br />
        <br />
        4.由于市场变化及各种以合理商业努力难以控制因素的影响，礼轻送小程序无法避免您提交的订单信息中的商品出现缺货情况;如您下单所购买的商品发生缺货，您有权取消订单，礼轻送小程序亦有权自行取消订单，若您已经付款，则为您办理退款。
        <br />
        <br />
        5.礼轻送小程序中的部分商品并非直接在礼轻送平台出售。您理解和授权礼轻送以您的名义采购您所下订单中的部分商品，以处理您的订单。
        <br />
        <br />
        6.您同意你的收礼人将您下单购买的商品转赠给第三方，并且同意无论第三方是否接受该商品，您自愿放弃该商品的所有权，不再要求礼轻送做退货或退款等操作。
      </p>
    </div>
    <div>
      <b class="title">三、配送及售后</b>
      <p>
        1.您在礼轻送小程序购买的商品将按照礼轻送小程序上收礼人所填写的送货地址进行配送。订单信息中列出的送达时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。您应当提醒收礼人清楚准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所购买的商品应仅由您的收礼人填写的联系人接受身份查验后接收商品，因收礼人变更联系人或相关配送信息而造成的损失由收礼人自行承担。
        <br />
        <br />
        2.因如下情况造成订单非正常延迟或无法配送等，礼轻送小程序将无法承担相关责任：
        <br />
        <br />
        （1）您的收礼人提供错误信息和不详细的地址;
        <br />
        <br />
        （2）货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果;
        <br />
        <br />
        （3）第三方快递或物流公司的非正常延迟;
        <br />
        <br />
        （4）不可抗力，例如:自然灾害、交通戒严、突发战争等。
        <br />
        <br />
        3.如您的收礼人在收货后发现商品质量问题，礼轻送可为您代安排和协调退货服务和退款。
      </p>
    </div>
    <div>
      <b class="title">四、用户行为规范</b>
      <p>
        1、您同意严格遵守以下义务:
        <br />
        （1）不得传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;
        <br />
        <br />
        （2）从中国大陆向境外传输资料信息时必须符合中国有关法规;
        <br />
        <br />
        （3）不得利用礼轻送小程序从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动;
        <br />
        <br />
        （4）不得干扰礼轻送小程序的正常运转，不得侵入礼轻送小程序及国家计算机信息系统;
        <br />
        <br />
        （5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，
        淫秽的、不文明的等信息资料;
        <br />
        <br />
        （6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论;
        <br />
        <br />
        （7）不得教唆他人从事本条所禁止的行为;
        <br />
        <br />
        （8）不得利用礼轻送小程序进行牟利性经营活动;
        <br />
        <br />
        （9）不得发布任何侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容。
        <br />
        <br />
        2、您须对自己在网上的言论和行为承担法律责任，您若在礼轻送小程序上散布和传播反动、色情或其它违反国家法律的信息，礼轻送小程序的系统记录有可能作为您违反法律的证据。
      </p>
    </div>
    <div>
      <b class="title">五、违约责任</b>
      <p>
        1.如果礼轻送发现或收到他人举报投诉您违反本协议约定的，礼轻送有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁的处罚，并公告处理结果。
        <br />
        <br />
        2.礼轻送有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
        <br />
        <br />
        3.您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任;礼轻送因此遭受损失的，您也应当一并赔偿。
        <br />
        <br />
        4.除非另有明确的书面说明，番茄科技不对礼轻送小程序的运营及其包含在礼轻送小程序上的信息、内容、材料、产品或服务作任何形式的、明示或默示的声明或担保(根据中华人民共和国法律另有规定的除外)。
        <br />
        <br />
        5.在法律允许的范围内，礼轻送在任何情况下对您或第三方的赔偿责任最高不超过您所下订单并支付的总金额。
      </p>
    </div>
    <div>
      <b class="title">六、法律管辖适用及其他</b>
      <p>
        1.本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决;协商不成时，任何一方均可向协议签订地人民法院提起诉讼。本协议签订地为番茄科技的注册地，即广东省中山市南区。
        <br />
        <br />
        2.如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
        <br />
        <br />
        3.本协议未明示授权的其他权利仍由礼轻送保留，您在行使这些权利时须另外取得礼轻送的书面许可。礼轻送如果未行使前述任何权利，并不构成对该权利的放弃。
      </p>
    </div>
    <div class="bottom" style="text-align:center;color:#bbb;font-size:15px">
      <div class="bottom-layout">
        <div style="margin-bottom:10px;text-align:center;">
          以上最终解释权归广东番茄科技有限公司所有
        </div>
        <div class="bottom-container">
          © 2021 Tomato Technology | 广东番茄科技有限公司 | <span>粤ICP备2021062901号-1</span>
          <!-- <a href="https://beian.miit.gov.cn" target="_blank"
            >粤ICP备2021062901号-1</a
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.agreement {
  padding: 20px;
  color: #000;
  .agreement-title {
    p {
      text-align: center;
      font-size: 18px;
      color: #000;
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  div {
    text-align: left;
    margin-bottom: 20px;
    p {
      line-height: 28px;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .bottom-container {
    text-align: center;
  }
}
</style>
